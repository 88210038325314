import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
const QatarPccUse = () => {
  return (
    <div className="pccImpContainer">
      <h2>
        Common Uses of Qatar <span>PCC</span>
      </h2>
      <p>A Qatar PCC is essential for various purposes, including:</p>
      <ul>
        <li>
          {" "}
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="eduCertIcon"
          ></FontAwesomeIcon>{" "}
          <span>Visa and Immigration:</span>: Required for visa applications,
          residency permits, and immigration procedures to verify the
          applicant’s background.
        </li>
        <li>
          {" "}
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="eduCertIcon"
          ></FontAwesomeIcon>{" "}
          <span>Employment:</span>: Employers in Qatar may request a PCC to
          ensure the suitability and reliability of potential employees.
        </li>
        <li>
          {" "}
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="eduCertIcon"
          ></FontAwesomeIcon>{" "}
          <span> Education:</span> Educational institutions in Qatar may require
          a PCC to ensure the safety and integrity of their campus environment.
        </li>
        <li>
          {" "}
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="eduCertIcon"
          ></FontAwesomeIcon>{" "}
          <span>Legal Proceedings:</span> Used for legal proceedings, adoption
          processes, and other personal matters requiring proof of a clean
          criminal record.
        </li>
      </ul>
      <p>
        Qatar PCC verification in Bangalore is essential for individuals
        planning to pursue opportunities in Qatar. Goodway Attestation Services
        offers expert assistance in navigating the complexities of the
        verification process, ensuring that documents meet Qatari legal
        standards and are accepted by Qatari authorities. With their efficient
        services and commitment to client satisfaction, Goodway Attestation
        Services is the trusted partner for Qatar PCC verification in Bangalore.
        Whether for immigration, employment, education, or personal matters,
        individuals can rely on Goodway Attestation Services for reliable and
        timely verification of their PCCs for Qatar.
      </p>
    </div>
  );
};

export default QatarPccUse;
