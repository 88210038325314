import React from "react";
import "./SeoContent.css";

const SeoContentHead = () => {
  return (
    <div className="seoContentHeadContainer">
      <h1>Embassy Attestation & Apostille Services in Bangalore</h1>
    </div>
  );
};

export default SeoContentHead;
