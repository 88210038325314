import React from "react";
import "./Footer.css";
import Footer2 from "./Footer2/Footer2";
import Footer1 from "./Footer1";
const Footer = () => {
  return (
    <div className="footerContainer">
     <Footer1/>
      <Footer2/>
    </div>
  );
};

export default Footer;
