import React from "react";

const BirthCertChallenges = () => {
  return (
    <div className="birthCertBenefits">
      <h2>
        Common Challenges in Birth Certificate<span>Apostille</span>{" "}
      </h2>
      <p>
        Obtaining a birth certificate apostille can present several challenges.
        Some common issues that individuals may face include:
      </p>
      <ul>
        <li>
          <span>Complex Procedures:</span>The apostille process involves
          multiple steps and authorities, making it complex and time-consuming
          for individuals to navigate on their own.
        </li>
        <li>
          <span>Varying Requirements: </span>Different countries have varying
          requirements for document attestation, which can add to the complexity
          of the process.
        </li>
        <li>
          <span>Legal and Regulatory Compliance:</span> Ensuring that all
          documents comply with the legal and regulatory requirements of both
          the home country and the destination country can be challenging.
        </li>
        <li>
          <span>Delays and Bureaucracy:</span> The apostille process can be
          subject to delays and bureaucratic hurdles, impacting the timelines
          for international plans.
        </li>
      </ul>
    </div>
  );
};

export default BirthCertChallenges;
