import React from "react";

const TrackData = () => {
  return (
    <div style={{ margin: "auto", width: "100%", maxWidth: "1200px" }}>
      <div className="iframe-container" style={{ height: "100vh", position: "relative" }}>
        <iframe
          src="https://goodwayattestation.com/crm/track.html"
          sandbox="allow-same-origin allow-scripts allow-forms"
          style={{
            border: "none",
            width: "100%",
            height: "100%",
            display: "block", // Prevents any inline spacing issues
            position: "absolute",
            top: "0",
            left: "0"
          }}
          title="Track Your Attestation"
        ></iframe>
      </div>
    </div>
  );
};

export default TrackData;
