import React from 'react'
import "./../../UaeAttestation/UaeBlore/UaeBlore.css";

const GermanyBloreMain = () => {
  return (
    <div className="uaeBloreMainContainer">
      <p>
        Germany is one of the well-known nations that welcome foreigners to live,
        work, or do business. Germany apostille of the document is a significant
        procedure that is to be performed mostly if you are planning to visit
        the Germany. Germany embassy apostille is a process of getting a signature
        or stamp on a document by the authorized department.
      </p>
      <p>
        It is generally required for attaining an education, family, &
        employment visa of Germany. Certificate apostille for Germany is needed to
        prove its authenticity for usage in the Germany. You can get Germany
        certificate apostille in Bangalore at Goodwayattestation which is a
        leading company for delivering timely, low-cost, & genuine services. It
        is approved by Ministries & embassies in India as well as abroad.
      </p>
    </div>
  )
}

export default GermanyBloreMain
