import React from 'react'
import MexicoReqMain from './MexicoReqMain'
import "./../../UaeAttestation/UaeReq/UaeReq.css";

const MexicoReq = () => {
  return (
    <div className="uaeReqContainer">
    <MexicoReqMain />
  </div>
  )
}

export default MexicoReq
