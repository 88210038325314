import React from 'react'
import QatarReqMain from './QatarReqMain'
import "./../../UaeAttestation/UaeReq/UaeReq.css";

const QatarReq = () => {
  return (
    <div className="uaeReqContainer">
    <QatarReqMain />
  </div>
  )
}

export default QatarReq
