import React from "react";
import "./OurServices.css";
const Anim = () => {
  return (
    <div className="animContainer">
  
    </div>
  );
};

export default Anim;
