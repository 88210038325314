import React from 'react'
import FranceReqMain from './FranceReqMain'
import "./../../UaeAttestation/UaeReq/UaeReq.css";

const FranceReq = () => {
  return (
    <div className="uaeReqContainer">
    <FranceReqMain />
  </div>
  )
}

export default FranceReq
