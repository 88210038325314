import React from "react";
import "./../../UaeAttestation/UaeService/UaeService.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
const FranceBloreCertMain = () => {
  return (
    <div className="uaeServiceMainContainer">
      <ul>
        <li>
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="eduCertIcon"
          ></FontAwesomeIcon>{" "}
          In Apostille step, the MEA issues a sticker that includes the name &
          information of the applicant. Sometimes, an MEA stamp is also required
          on the documents along with the apostille sticker especially for
          commercial documents. It is the last stage of apostille & is done by
          the central government.
        </li>
        <li>
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="eduCertIcon"
          ></FontAwesomeIcon>{" "}
          There are various levels of authorization that are included in the
          document apostille process. The procedure for the attestation of
          educational certificates will vary as it depends on the origin or
          sorts of the document. Personal documents need legalization from
          stages of government departments. Commercial documents are validated
          by a combination of governmental & non-governmental organizations.
        </li>
        <li>
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="eduCertIcon"
          ></FontAwesomeIcon>{" "}
          You can get France Attestation service in Bangalore for certificates
          at Goodwayattestation which is approved by Ministries & embassies in
          India as well as abroad. We provide you best quality services & will
          complete the entire procedure of your document attestation faster.
        </li>
      </ul>
    </div>
  );
};

export default FranceBloreCertMain;
