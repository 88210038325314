import React from "react";

const MarriageCertProcedure = () => {
  return (
    <div className="birthCertProcedureContainer">
      <h2>
        Procedure for Marriage Certificate <span>Apostille </span> in Bangalore
      </h2>
      <p>
        The process of obtaining a marriage certificate apostille in Bangalore
        involves several steps. Goodway Attestation Services ensures that each
        step is meticulously followed to guarantee the authenticity and
        acceptance of the document abroad. The main steps in the marriage
        certificate apostille process include:
      </p>
      <ol>
        <li>
          1. <span> Notary Attestation:</span> The initial step involves getting
          the marriage certificate attested by a notary public. The notary
          verifies the authenticity of the document and adds their official seal
          and signature.
        </li>
        <li>
          2. <span>Home Department Attestation: </span>After notary attestation,
          the document is submitted to the Home Department. This department
          verifies the authenticity of the marriage certificate and adds its
          seal and signature.
        </li>
        <li>
          3. <span>MEA (Ministry of External Affairs) Attestation:</span>: The
          final step involves the Ministry of External Affairs. The MEA verifies
          the document and adds its official apostille sticker, confirming its
          authenticity for international use.
        </li>
      </ol>
    </div>
  );
};

export default MarriageCertProcedure;
