import React from "react";
import "./../../UaeAttestation/UaeService/UaeService.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
const ItalyBloreCertMain = () => {
  return (
    <div className="uaeServiceMainContainer">
      <ul>
        <li>
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="eduCertIcon"
          ></FontAwesomeIcon>{" "}
          Apostille for Italy in India is a branched process and is proceeding
          with the intervention of Apostille services in India. Validation for
          Italy is the procedure of authenticating your documents to obtain a
          visa or grow your business in Italy. Document legalization is the
          procedure of acquiring the MEA sticker on the document.
        </li>
        <li>
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="eduCertIcon"
          ></FontAwesomeIcon>{" "}
          It is a part of the legalization of the needed certificate
          authentication that indicates the trustworthiness of your documents &
          your purpose of visiting Italy. Apostille documents in India are
          provided by the MEA (Ministry of External Affairs) which is the
          department of the Indian government that handles foreign affairs. MEA
          is a central government authentication that is a part of the process
          where the MEA renders with an MEA sticker.
        </li>
        <li>
          <FontAwesomeIcon
            icon={faAnglesRight}
            className="eduCertIcon"
          ></FontAwesomeIcon>{" "}
          You can get an Italy Apostille services in Bangalore at
          Goodwayattestation which is approved by Ministries & embassies in
          India as well as abroad. We provide you best quality services & will
          complete the entire procedure of your documents attestation
          hassle-free.
        </li>
      </ul>
    </div>
  );
};

export default ItalyBloreCertMain;
