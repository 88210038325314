import React from 'react'
import "./Marquee.css"
const HomeMarqueeHead = () => {
  return (
    <div className='marqueeHead'>
      <h2>Countries We Serve</h2>
      <div className='marqueeUnderline'></div>
    </div>
  )
}

export default HomeMarqueeHead
