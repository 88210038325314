import React from 'react'
import ItalyReqMain from './ItalyReqMain'
import "./../../UaeAttestation/UaeReq/UaeReq.css";

const ItalyReq = () => {
  return (
    <div className="uaeReqContainer">
    <ItalyReqMain />
  </div>
  )
}

export default ItalyReq
