import React from 'react'
import AustraliaReqMain from './AustraliaReqMain'

const AustraliaReq = () => {
  return (
    <div className="uaeReqContainer">
    <AustraliaReqMain />
  </div>
  )
}

export default AustraliaReq
