import React from 'react'
import "./WhyBlock.css"
import WhyBlockMain from './WhyBlockMain';
const WhyBlock = () => {
  return (
    <div className='whyBlockContainer'>
      <WhyBlockMain/>
    </div>
  )
}

export default WhyBlock
