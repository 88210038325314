import React from 'react'
import "./ContactUs.css"
const ContactUsHead = () => {
  return (
    <div className='contactHeadContainer'>
      <h2>Contact <span>Us</span></h2>
    </div>
  )
}

export default ContactUsHead
