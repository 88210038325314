import React from "react";
import "./../../UaeAttestation/UaeAbout/UaeAbout.css";
const BahrainAboutMain = () => {
  return (
    <div className="uaeAboutUsMainContainer">
      <div className="uaeAboutUsMainContent">
        <ul>
          <li>
            Bahrain is a rapidly growing business hub in the Middle East with
            the fastest-growing economy in the Arab world. Its open economy and
            investor-friendly policies attract significant numbers of Indians.
          </li>
          <li>
            Ensure a hassle-free visit or immigration to Bahrain with Goodway
            Attestation's expert document attestation services. Our
            professionals are well-versed in the rules and procedures of the
            Bahrain Embassy for travel, immigration, and visa processes.
          </li>
          <li>
            Get help from the professionals at Goodway Attestation in getting
            your documents attested by the Embassy of Bahrain. This will
            comprehensively minimise all possible hassles at the major counters
            during your travel to Bahrain.
          </li>
          <li>
            Enhance the authenticity of your documents with Goodway Attestation.
            We assist in getting your documents attested by the Ministry of
            Foreign Affairs, Bahrain, ensuring a smooth scrutiny process for
            your visit or immigration.
          </li>
          <li>
            Ensure hassle-free joining formalities at your new company in
            Bahrain with educational certificate attestation by Goodway
            Attestation. Our professionals provide expert guidance and
            assistance.
          </li>
          <li>
            Get your marriage certificate attested for Bahrain with Goodway
            Attestation for a smooth process. Whether you're traveling together
            or need your spouse to join you in Bahrain, we ensure hassle-free
            attestation.
          </li>
          <li>
            Contact Goodway Attestation today to get your educational
            certificates, marriage certificate, work experience certificates
            attested for Bahrain in Bengaluru.
          </li>
        </ul>
      </div>
      <div className="uaeAboutUsMainContentImg">
        <img
          src="https://images.unsplash.com/photo-1609097160192-8a8709d2537f?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MzF8fGJhaHJhaW58ZW58MHx8MHx8fDA%3D"
          alt="qatarAbout"
        />
      </div>
    </div>
  );
};

export default BahrainAboutMain;
