import React from 'react'
import OmanReqMain from './OmanReqMain'
import "./../../UaeAttestation/UaeReq/UaeReq.css";

const OmanReq = () => {
  return (
<div className="uaeReqContainer">
      <OmanReqMain />
    </div>
  )
}

export default OmanReq
