import React from 'react'
import "./../../UaeAttestation/UaeBlore/UaeBlore.css";

const AustraliaBloreMain = () => {
  return (
    <div className="uaeBloreMainContainer">
    <p>
      Australia is one of the well-known nations that welcome foreigners to live,
      work, or do business. Australia apostille of the document is a significant
      procedure that is to be performed mostly if you are planning to visit
      the Australia. Australia embassy apostille is a process of getting a signature or
      stamp on a document by the authorized department.
    </p>
    <p>
      It is generally required for attaining an education, family, &
      employment visa of Australia. Certificate apostille for Australia is needed to
      prove its authenticity for usage in the Australia. You can get Australia certificate
      apostille in Bangalore at Goodwayattestation which is a leading
      company for delivering timely, low-cost, & genuine services. It is
      approved by Ministries & embassies in India as well as abroad.
    </p>
  </div>
  )
}

export default AustraliaBloreMain
