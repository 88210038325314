import React from 'react'
import VietnamReqMain from './VietnamReqMain'
import "./../../UaeAttestation/UaeReq/UaeReq.css";

const VietnamReq = () => {
  return (
    <div className="uaeReqContainer">
    <VietnamReqMain />
  </div>
  )
}

export default VietnamReq
