import React from 'react'
import "./Footer2.css"
const Footer2Line = () => {
  return (
    <div className='footerLine'>
      
    </div>
  )
}

export default Footer2Line
