import React from 'react'
import "./OurServices.css"
const OurServicesHead = () => {
  return (
    <div className='ourServiceHeadContainer'>
      <h2>Our Services</h2>
      <div className="underline"></div>
    
    </div>
  )
}

export default OurServicesHead
